import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Module 3";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "provisional-prod-deploy"
    }}>{`Provisional prod deploy`}</h1>
    <p>{`Here we deploy the frontend to production still using the `}<inlineCode parentName="p">{`dev`}</inlineCode>{` stage of the backend. The goal is to make sure it works and see how our app behaves on a real domain.`}</p>
    <p>{`We're using Zeit but you can achieve the same flow with Netlify.`}</p>
    <Vimeo id="394978218" mdxType="Vimeo" />
    <h2 {...{
      "id": "verify-it-works"
    }}>{`Verify it works`}</h2>
    <Vimeo id="394978438" mdxType="Vimeo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      